import React from 'react'
import Login from '../security/Login';
import './App.css';
import { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import axios from 'axios';

export default function Dashboard() {
  return (
    <div className='container-fluid'>
      <div className='col-12'>
        <div className='row'>
        <Sidebar/>
        </div>
      </div>
    </div>
  )
}

