import {Usertab} from "../../../interface/AllUserInterface";
import {UserData} from "../../../interface/UserDataInterface";
import useGetAllUser from "../../../hook/useGetAllUser";
import usePatchUser from "../../../hook/usePatchUser";
import {ProfilProps} from "../../../interface/User";
import React, {useState} from "react";

export default function RolesUser({ userId }: ProfilProps) {
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [selectedUser, setSelectedUser] = useState<Usertab | null>(null);

    const { users } = useGetAllUser();
    const patchUser = usePatchUser();

    const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedUserId = parseInt(event.target.value);
        const selectedUser = users && users.find(user => user.id === selectedUserId); // Ajoutez une vérification ici
        setSelectedUser(selectedUser || null);
        setSelectedRoles(selectedUser ? selectedUser.roles : []);
    };

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (selectedUser) {
            const dataToUpdate: UserData = {
                roles: selectedRoles,
            };
            await patchUser(selectedUser.id, dataToUpdate);
            setUpdateSuccess(true);
        }
    };

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedRole = event.target.value;
        setSelectedRoles((prevRoles: string[]) => {
            if (prevRoles.includes(selectedRole)) {
                return prevRoles.filter(role => role !== selectedRole);
            } else {
                return [...prevRoles, selectedRole];
            }
        });
    };

    return (
        <div className="container mt-4">
            <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                    <h2>Modifier le rôle</h2>
                    <label className="form-label" htmlFor="user">Sélectionner un utilisateur :</label>
                    <select className="form-control" id="user" value={selectedUser?.id || ''} onChange={handleUserChange}>
                        <option value="">Sélectionner un utilisateur</option>
                        {users && users.length > 0 &&
                            users.map((user: Usertab) => (
                                <option key={user.id} value={user.id}>
                                    {user.lastName} {user.firstName}
                                </option>
                            ))}
                    </select>
                </div>

                <div className="mb-3">
                    <label>Choisir les rôles:</label>
                    <div>
                        {['ROLE_SUPER_ADMIN', 'ROLE_CHEF_PROJET', 'ROLE_EQUIPE_VIDEO', 'ROLE_REDACTEUR', 'ROLE_PROFESSEUR', 'ROLE_TRADUCTEUR',
                            'ROLE_REGISSEUR', 'ROLE_MONTEUR', 'ROLE_CADREUR',
                            'ROLE_MIXEUR', 'ROLE_ECLAIREUR', 'ROLE_REAL', 'ROLE_SON'].map(role => (
                            <div key={role} className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={role}
                                    checked={selectedRoles.includes(role)}
                                    onChange={handleRoleChange}
                                />
                                <label className="form-check-label">
                                    {role}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <button type="submit" className="btn btn-primary">Mettre à jour</button>
            </form>

            {updateSuccess &&
                <div className="alert alert-success mt-3">
                    Profil modifié avec succès !
                </div>
            }
        </div>
    );
};