import React, { useState } from "react";
import usePostUser from "../../../hook/usePostUser";
import bcrypt from "bcryptjs-react";

export default function FormCreateUser() {
    const [lastname, setLastname] = useState<string>("Nom")
    const [firstname, setFirstname] = useState<string>("Prénom")
    const [email, setEmail] = useState<string>("Email")
    const [roles, setRoles] = useState<string[]>([])
    const [password, setPassword] = useState<string>("Password")
    const [sent, setSent] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const postUser = usePostUser();

    const  handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await postUser(firstname, lastname, email, password);
            setSent(true);
            setError(false);
        } catch (error) {
            console.error(error);
            setError(true);
            setSent(false);
        }
    }

    return (
        <div className="container mt-4">
            <h2>Créer un Utilisateur</h2>
            <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                    <label htmlFor="lastname" className="form-label">Nom:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="firstname" className="form-label">Prénom:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email:</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Mot de passe:</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-primary">Soumettre</button>
            </form>

            {sent && !error && <p style={{ color: 'green', marginTop: '1rem' }}>Utilisateur ajouté avec succès!</p>}
            {error && !sent && <p style={{ color: 'red', marginTop: '1rem' }}>Une erreur s'est produite lors de l'ajout de l'utilisateur.</p>}
        </div>
    );
};