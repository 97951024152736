import {DeleteProjectInterface} from "../../../interface/DeleteProjectInterface";
import FormCreateAsset from "../Asset/FormCreateAsset";
import React from "react";
import DeleteAssetFromProject from "../Asset/DeleteAssetFromProject";
import FormAddUserinTeam from "./FormAddUserinTeam";
import DeleUserFromTeam from "./DeleUserFromTeam";
import {ChefProjetPropsInterface} from "../../../interface/ChefProjetPropsInterface";

export default function TeamChefProjet({ team }: ChefProjetPropsInterface) {

    return(
        <>
            <FormAddUserinTeam team={team}/>
            <DeleUserFromTeam teamId={team}/>
        </>
    );
}