import { useEffect, useState } from 'react';
import axios from 'axios';
import {Project} from "../interface/ProjectIdinterface";
import Cookies from "js-cookie";

const useGetProjectById = (id: number | null) => {
    const [project, setProject] = useState<Project | null>(null);

    const token = Cookies.get('token');

    useEffect(() => {
        const fetchProjectId = async () => {
            const header = {
                Authorization: `Bearer ${token}`
            }
            try {
                if (id) {
                    const response = await axios.get('http://dam.spiritofweb.fr:8741/api/projects/' + id, {headers: header})
                    setProject(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchProjectId();
    }, [id]);

    return {
        project,
    };
};

export default useGetProjectById;