import React from "react";

const MetadataDisplay = ({ version, isTeamVideo }) => {

  return (
    <div>
      <div className="row">
        <div className="col-12 text-center my-3">
          <video className="w-75" controls src={version.path}></video>
        </div>
        <h2 className="text-center">Metadata</h2>

        {/* Format */}
        {isTeamVideo && (
          <div className="col-lg-6 col-12">
            <p>Format: {version.metadata.format}</p>
          </div>
        )}

        {/* Codec */}
        {isTeamVideo && (
          <div className="col-lg-6 col-12">
            <p>Codec: {version.metadata.codec}</p>
          </div>
        )}

        {/* Ratio Aspect */}
        {isTeamVideo && (
          <div className="col-lg-6 col-12">
            <p>Ratio Aspect: {version.metadata.ratioAspect}</p>
          </div>
        )}

        {/* Staff */}
        {isTeamVideo && (
          <div className="col-lg-6 col-12">
            <p>Staff: {version.metadata.staff.join(", ")}</p>
          </div>
        )}

        {/* TimeCode */}
        {isTeamVideo && (
          <div className="col-lg-6 col-12">
            <p>Timecode : {version.metadata.timecode.join(", ")}</p>
          </div>
        )}

        {/* Bit Rate */}
        {isTeamVideo && (
          <div className="col-lg-6 col-12">
            <p>Bit Rate : {version.metadata.bitRate}</p>
          </div>
        )}

        {/* Image Rate */}
        {isTeamVideo && (
          <div className="col-lg-6 col-12">
            <p>Image Rate : {version.metadata.imageRate}</p>
          </div>
        )}

        {/* Matériaux erq */}
        {isTeamVideo && (
          <div className="col-lg-6 col-12">
            <p>Matériaux requis: {version.metadata.requireMat.join(", ")}</p>
          </div>
        )}

        {/* Durée */}
        {isTeamVideo && (
          <div className="col-lg-6 col-12">
            <p>Durée : {formatDuration(version.metadata.duration)}</p>
          </div>
        )}

        {/* tag */}
        {isTeamVideo && (
          <div className="col-lg-6 col-12">
            <p>Tag : {version.metadata.tag}</p>
          </div>
        )}

        {/* New version heure */}
        {isTeamVideo && (
          <div className="col-lg-6 col-12">
            <p>Nouvelle version: {version.metadata.dateHour} </p>
          </div>
        )}

        {/* description */}
        <div className="col-12">
          <p>Description : {version.metadata.description}</p>
        </div>

        {/* Langue */}
        <div className="col-lg-6 col-12">
          <p>Languages : {version.metadata.language} </p>
        </div>

        {/* Leieu de tournage */}
        <div className="col-lg-6 col-12">
          <p>Place : {version.metadata.place} </p>
        </div>

        {/* Thème */}
        <div className="col-lg-6 col-12">
          <p>Thème {version.metadata.theme} </p>
        </div>

        {/* Instrument use */}
        <div className="col-lg-6 col-12">
          <p>Instrument : {version.metadata.instrument}</p>
        </div>

        {/* Objectif */}
        <div className="col-lg-6 col-12">
          <p>Objectif : {version.metadata.objectif} </p>
        </div>

        {/* Level */}
        <div className="col-lg-6 col-12">
          <p>Level : {version.metadata.level} </p>
        </div>
      </div>
    </div>
  );
};


// Fonction pour formater la durée au format "hh:mm:ss"
function formatDuration(durationString) {
  const duration = new Date(durationString);
  const hours = duration.getUTCHours().toString().padStart(2, "0");
  const minutes = duration.getUTCMinutes().toString().padStart(2, "0");
  const seconds = duration.getUTCSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
}

export default MetadataDisplay;
