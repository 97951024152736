import { useEffect } from 'react';
import axios from 'axios';
import { UserData } from '../interface/UserDataInterface';
import Cookies from "js-cookie";

const usePatchUser = () => {

    const token = Cookies.get('token');
    return async (userId: number, data: UserData) => {
        const header = {
            "Content-Type": "application/merge-patch+json",
            Authorization: `Bearer ${token}`
        }
        try {
            if (userId && data) {
                const response = await axios.patch(`http://dam.spiritofweb.fr:8741/api/users/${userId}`, data, {headers: header});
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export default usePatchUser;
