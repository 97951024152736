import React, {useContext} from "react";
import FormCreateUser from "./FormCreateUser";
import DeleteUser from "./DeleteUser";
import RolesUser from "./RolesUser";
import {UserContext} from "../../../context/UserContext";


export default function UserSuperAdmin() {
    const [user] = useContext(UserContext);
    return(
        <>
            <FormCreateUser/>
            <RolesUser userId={user.id}/>
            <DeleteUser/>
        </>
    );
}