import {DeleteProjectInterface} from "../../../interface/DeleteProjectInterface";
import FormCreateAsset from "./FormCreateAsset";
import React from "react";
import DeleteAssetFromProject from "./DeleteAssetFromProject";
import {ChefProjetPropsInterface} from "../../../interface/ChefProjetPropsInterface";

export default function AssetChefProjet({ project }: ChefProjetPropsInterface) {

    return(
        <>
            <FormCreateAsset projectId={project}/>
            <DeleteAssetFromProject projectId={project}/>
        </>
    );
}