import React from "react";

const VersionDropdown = ({ versions, onChange }) => {
  return (
    <div className="text-center mb-3">
      <label>Selectionner une version:</label>

      <select onChange={onChange}>
        <option value="">Selectionner une version</option>
        {/*Map sur chaque version */}
        {versions.map((version) => (
          <option key={version.id} value={version.id}>
            Version {version.num}
          </option>
        ))}
      </select>
    </div>
  );
};

export default VersionDropdown;
