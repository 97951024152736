import axios from 'axios';
import Cookies from "js-cookie";

const usePostUser = () => {
    const token = Cookies.get('token');
    return async (    firstname: string,
                      lastname: string,
                      email: string,
                      password: string) => {

        const header = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/ld+json"
        }
        try {
            if (firstname && lastname && email && password) {
                const response = await axios.post('http://dam.spiritofweb.fr:8741/createUser', {
                    firstname: firstname,
                    lastname: lastname,
                    email: email,
                    // comments: [],
                    // roles: [],
                    password: password,
                    //  icon: "/api/icons/1",
                    // teams: [],
                    description: null
                }, {headers: header});
            }
        } catch (error) {
            // @ts-ignore
            console.error(error);
        }
    };
};

export default usePostUser;
