// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
  max-height: 500px;
  overflow-y: scroll;
}

.chat-bubble {
  display: inline-block;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
}

.chat-text {
  margin: 0;
}

.left {
  background-color: #f1f0f0;
  float: left;
  clear: both;
}

.right {
  background-color: #161C3D;
  color: #fff;
  float: right;
  clear: both;
}

.chat-form {
  margin-top: 20px;
}

.chat-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

.chat-form button {
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Comments/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".chat-container {\n  max-height: 500px;\n  overflow-y: scroll;\n}\n\n.chat-bubble {\n  display: inline-block;\n  margin-bottom: 10px;\n  padding: 10px 15px;\n  border-radius: 10px;\n  font-size: 14px;\n}\n\n.chat-text {\n  margin: 0;\n}\n\n.left {\n  background-color: #f1f0f0;\n  float: left;\n  clear: both;\n}\n\n.right {\n  background-color: #161C3D;\n  color: #fff;\n  float: right;\n  clear: both;\n}\n\n.chat-form {\n  margin-top: 20px;\n}\n\n.chat-form textarea {\n  width: 100%;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  resize: none;\n}\n\n.chat-form button {\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
