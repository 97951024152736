import React, {useState} from 'react'
import User from "../components/User";
import Sidebar from "../components/Sidebar/profile";
import Setting from "../components/User/setting";
import {ProfilProps} from "../interface/User";
import {Button} from "reactjs-popup/dist/stories/components";
import BackPage from "../components/Button/BackPage";

export default function Profile() {
    const [activeMenuItem, setActiveMenuItem] = useState("info");


    const handleMenuItemClick = (item: React.SetStateAction<string>) => {
        setActiveMenuItem(item);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onMenuItemClick={handleMenuItemClick} />
                <div className="col-lg-10 col-md-8 col-sm-12 col-xs-12"> {/* Utilisation des classes de Bootstrap pour les colonnes */}
                    <BackPage inBtn={'Accueil'}/>
                    {activeMenuItem === "info" && <User />}
                    {activeMenuItem === "setting" && <Setting />}
                </div>
            </div>
        </div>
    )
}
