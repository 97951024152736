import { useEffect, useState } from 'react';
import axios from 'axios';
import {Project} from "../interface/ProjectIdinterface";
import Cookies from "js-cookie";
import {Icon} from "../interface/IconInterface";

const useGetIcon = (id: number | null) => {
    const [icon, setIcon] = useState<Icon | null>(null);

    const token = Cookies.get('token');

    useEffect(() => {
        const fetchIcon = async () => {
            const header = {
                Authorization: `Bearer ${token}`
            }
            try {
                if (id) {
                    const response = await axios.get('http://dam.spiritofweb.fr:8741/api/icons/' + id, {headers: header})
                    setIcon(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchIcon();
    }, [id]);

    return {
        icon,
    };
};

export default useGetIcon;