import axios from "axios";
import {useEffect, useState} from "react";
import Cookies from "js-cookie";
import {Categorytab} from "../interface/AllCategoryInterface";

const useGetAllCategories = () => {

    const [categories, setCategories] = useState<Categorytab[]>([]);
    const token = Cookies.get('token');

    useEffect(() => {
        const fetchCategories = async () => {
            const header = {
                Authorization: `Bearer ${token}`
            };

            try {
                const response = await axios.get('http://dam.spiritofweb.fr:8741/api/category_documents', { headers: header });
                setCategories(response.data['hydra:member']);
            } catch (error) {
                console.error('useGetAllCategories - error:', error);
            }
        };

        fetchCategories();
    }, [token]);



    return {
        categories,
    };
};

export default useGetAllCategories;
