import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CatcherAxios = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const axiosInterceptor = axios.interceptors.response.use(
      
      response => response,
      error => {
        if (error.response.status === 401  ) {
          // Quand ca catch une 401  ca redirige vers / 
          navigate('/');
        } 
        return Promise.reject(error);
      }
    );
    return () => {
      // Cleanup the interceptor when the component unmounts
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, [navigate]);

  // quand ya pas d'erreur
  return null;
};

export default CatcherAxios;
