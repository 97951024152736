import React, { useState } from "react";
import usePostTeam from "../../../hook/usePostTeam";
import post from "axios";

export default function FormCreateTeam() {
    const [name, setName] = useState<string>("Nom de la Team")
    const [sent, setSent] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const postTeam = usePostTeam();

    const  handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await postTeam(name);
            setSent(true);
            setError(false);
        } catch (error) {
            console.error(error);
            setError(true);
            setSent(false);
        }
    }

    return (
        <div className="container mt-4">
            <h2>Créer une Team</h2>
            <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                    <label htmlFor="teamName" className="form-label">Nom:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="teamName"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-primary">Soumettre</button>
            </form>

            {sent && !error && <p style={{ color: 'green' }}>Team créée avec succès!</p>}
            {error && !sent && <p style={{ color: 'red' }}>Une erreur s'est produite lors de la création de la team.</p>}
        </div>
    );
};