import React from 'react'
import DragNDrop from "../components/Document/DragNDrop";

export default function contact() {
  return (
      <>
        <div>contact</div>
        <DragNDrop assetId={1} projectId={2}/>
      </>
  )
}
