import { useEffect, useState, useContext } from "react";
import axios from "axios";
import React from "react";
import Sidebar from "../components/Sidebar";
import DragNDrop from "../components/Document/DragNDrop";
import DeleteDocument from "../components/Document/DeleteDocument";
import Cookies from "js-cookie";
import "./App.css";
import avatar from "../public/avatar.png";
import Popup from "reactjs-popup";
import { UserContext } from "../context/UserContext";
import { useParams, Link } from "react-router-dom";

export default function StockageCommun() {
  const { projectId, assetId } = useParams();
  const [projectInfo, setProjectInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser, getUserInfo] = useContext(UserContext);

  const afficherBouton =
    user.roles.includes("ROLE_PROFESSEUR") ||
    user.roles.includes("ROLE_TRADUCTEUR") ||
    user.roles.includes("ROLE_REDACTEUR");

  const fetchProjectInfo = async () => {
    try {
      const token = Cookies.get("token");

      const response = await axios.get(
        `http://dam.spiritofweb.fr:8741/api/project/${projectId}/assets/${assetId}/documents`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProjectInfo(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProjectInfo();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar />
        <div className="d-flex flex-column align-items-center col-8 mx-auto">
          <h3 className="my-3">Stockage commun</h3>
          <div className="files">
            {afficherBouton && (
              <Popup
                trigger={<button className="newFile">Nouveau fichier</button>}
              >
                <DragNDrop assetId={assetId} projectId={projectId} />
              </Popup>
            )}

            {projectInfo.length > 0 ? (
              projectInfo.map((project) => (
                <div className="file" key={project.id}>
                  <p className="filename">
                    <Link to={project.url} target="_blank">
                      <img
                        src={avatar}
                        style={{
                          width: "1.8rem",
                          height: "1.8rem",
                          marginRight: "0.5rem",
                        }}
                        alt="Avatar"
                      />
                      {project.name}
                    </Link>
                  </p>
                </div>
              ))
            ) : (
              <p>Aucun document n'est disponible.</p>
            )}
          </div>
          <div className="col-12 w-50 my-3">
            <DeleteDocument assetId={assetId} projectId={projectId} />
          </div>
        </div>
      </div>
    </div>
  );
}
