import { useEffect, useState } from 'react';
import axios from 'axios';
import { Usertab } from "../interface/AllUserInterface";
import Cookies from "js-cookie";

const useGetAllUser = () => {
    const [users, setUsers] = useState<Usertab[] | null>(null);
    const token = Cookies.get('token');

    useEffect(() => {
        const fetchUsers = async () => {
            const header = {
                Authorization: `Bearer ${token}`
            };

            try {
                const response = await axios.get('http://dam.spiritofweb.fr:8741/api/users', { headers: header });
                setUsers(response.data['hydra:member']);
            } catch (error) {
                console.error(error);
            }
        };

        fetchUsers();
    }, [token]);

    // Retourne les utilisateurs, même s'ils sont actuellement à null (le temps que l'appel API se termine)
    return {
        users,
    };
};

export default useGetAllUser;