import React from "react";
import { DeleteProjectInterface } from "../../../interface/DeleteProjectInterface";
import useDeleteProject from "../../../hook/useDeleteProject";

export default function DeleteProject({ projectId }: DeleteProjectInterface) {
    const deleteProject = useDeleteProject();

    const handleDelete = async () => {
        await deleteProject(Number(projectId));
    };

    return (
        <div className="container mt-4">
            <div className="alert alert-danger">
                <p>Attention, vous êtes sur le point de supprimer le projet. Cette action est irréversible.</p>
                <button className="btn btn-danger" onClick={handleDelete}>Supprimer le projet</button>
            </div>
        </div>
    );
};