import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./style.css";

const AllComments = ({
  comments,
  setComments,
  contentComment,
  setContentComment,
  onSubmit,
  user,
}) => {
  const { idProject } = useParams();
  const location = useLocation();
  return (
    <React.Fragment>
      <h2>Commentaires :</h2>

      <div className="chat-container">
        {comments.comments.map((comment) => (
          <div
            key={comment.id}
            className={`chat-bubble ${
              /* Si l'user co est l'auteur du message, on ajoute la class droite sinon gaucbe */
              comment.author.firstName === user.firstName ? "right" : "left"
            }`}
          >
            <p className="chat-text">
              {/* Si l'user co est l'auteur du message : on ecrit moi à la place du prénom */}
              {comment.author.firstName === user.firstName
                ? "Moi"
                : comment.author.firstName}{" "}
              : {comment.content}
            </p>
          </div>
        ))}
      </div>

      <form onSubmit={onSubmit} className="chat-form">
        <textarea
          name=""
          id=""
          cols="30"
          rows="3"
          value={contentComment}
          onChange={(event) => {
            setContentComment(event.target.value);
          }}
        ></textarea>

        <button type="submit" className="btn btn-primary">
          Envoyer
        </button>
      </form>
    </React.Fragment>
  );
};

export default AllComments;
