import React from 'react'
import { Link } from 'react-router-dom';

export default function ErrorPage() {
return (
    <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', color: '#161C3D' }}>
        <div className="text-center">
            <h1 className="display-4">Page non trouvée</h1>
            <p className="lead">Désolé, la page que vous recherchez n'existe pas.</p>
            <Link to="/" className="btn btn-primary mt-3">Retourner à la page d'accueil</Link>
        </div>
    </div>
    );
}