import React, {useState} from "react";
import useGetAllUser from "../../../hook/useGetAllUser";
import {Usertab} from "../../../interface/AllUserInterface";
import usePostUser from "../../../hook/usePostUser";
import useDeleteUser from "../../../hook/useDeleteUserById";
import {useNavigate} from "react-router-dom";

export default function DeleteUser() {
    const { users } = useGetAllUser();
    const deleteUser = useDeleteUser();
    const navigate = useNavigate();
    const [selectedUser, setSelectedUser] = useState<Usertab | null>(null);
    const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedUserId = event.target.value;
        const selectedUser = users?.find(user => user.id === Number(selectedUserId)) || null;
        setSelectedUser(selectedUser);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (selectedUser) {
            deleteUser(selectedUser.id);
            let url = `/`;
            navigate(url);
        }
    };

    return (
        <div className="container mt-4">
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <h2>Supprimer un User</h2>
                    <label className="form-label" htmlFor="user">Select User:</label>
                    <select className="form-control" id="user" value={selectedUser?.id || ''} onChange={handleUserChange}>
                        {users && users.length > 0 &&
                            users.map((user: Usertab) => (
                                <option key={user.id} value={user.id}>
                                    {user.lastName} {user.firstName}
                                </option>
                            ))}
                    </select>
                </div>
                <button type="submit" className="btn btn-danger">Delete</button>
            </form>
        </div>
    );
};
