import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Sidebar from "../components/Sidebar";
import UploadVideoVersion from "../components/UploadVideoVersion/index";
import MetadataVersion from "../components/UploadVideoVersion/MetadataVersion";



const UploadVideo = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [path, setPath] = useState("");
  const [metadata, setMetadata] = useState({
    format: "",
    codec: "",
    ratioAspect: "",
    imageRate: "",
    bitRate: null,
    timecode: [],
    requireMat: [],
    language: "",
    description: "",
    duration: null,
    thumbnail: "",
    tag: "",
    place: "",
    theme: "",
    instrument: null,
    dateHour: null,
    staff: null,
    objectif: "",
    level: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const token = Cookies.get("token");
  const { projectId, assetId } = useParams();

  useEffect(() => {
    // Je récupère la dernière métadata enregistrer en BDD
    axios
      .get(
        `http://dam.spiritofweb.fr:8741/api/project/${projectId}/assets/${assetId}/metadata/last`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      // Une fois que la réponse est reçue avec succès, nous mettons à jour l'état "metadata" avec les données reçues.
      .then((response) => {
        setMetadata(response.data);
        setIsLoading(false); // Update isLoading after data is fetched
      })
      .catch((error) => {
        /* Si ya une error :  */
        console.error("Error fetching latest metadata:", error);
        setIsLoading(false);
      });
  }, []); // Le tableau de dépendances vide assure que cet effet ne s'exécute qu'une seule fois

  /* Gestion du path */
  const handlePathSubmit = (event) => {
    event.preventDefault();
    setStep(2);
  };

  /* Gestion des métadata */
  const handleMetadataSubmit = (event) => {
    event.preventDefault();

    // Mets toutes les données du formulaire dans un object data
    const data = {
      path: path, // On récupère path
      ...metadata, // On récupère toutes les information de l'object metadata
    };
    const jsonData = JSON.stringify(data);
    axios
      .post(
        `http://dam.spiritofweb.fr:8741/api/project/${projectId}/assets/${assetId}/versionings/add`,
        jsonData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error submitting version and metadata:", error);
      });
  };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            fontSize: "24px", // Taille de la police
            fontWeight: "bold", // Gras
            color: "#161C3D", // Couleur du texte (par exemple, bleu)
            textAlign: "center", // Centrer le texte horizontalement
            marginTop: "20px", // Marge supérieure de 20 pixels
          }}
        >
          En cours de chargement...
        </div>
      ) : (
        <React.Fragment>
          <div className="container-fluid">
            <div className="col-12">
              <div className="row">
                <Sidebar></Sidebar>
                <div className="col-8">
                  {step === 1 && (
                    <UploadVideoVersion
                      handlePathSubmit={handlePathSubmit}
                      path={path}
                      setPath={setPath}
                    />
                  )}
                  {step === 2 && (
                    <>
                      <h2 className="text-center">
                        Ajouter / Mettre à jour les métadatas
                      </h2>

                      <MetadataVersion
                        handleMetadataSubmit={handleMetadataSubmit}
                        metadata={metadata}
                        setMetadata={setMetadata}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default UploadVideo;
