import axios from 'axios';
import Cookies from "js-cookie";

const useAddUserInTeamById = () => {
    const token = Cookies.get('token');
    const addUserInTeam = async (teamId: number, userId: number) => {
        const header = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
        try {
            if (teamId && userId) {
                const response = await axios.post(`http://dam.spiritofweb.fr:8741/api/add/team/${teamId}/user/${userId}`,{
                    teamId: teamId,
                    userId: userId
                },{headers: header});
            }
        } catch (error) {
            console.error(error);
        }
    };

    return addUserInTeam;
};

export default useAddUserInTeamById;
