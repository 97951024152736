import { useEffect } from 'react';
import axios from 'axios';
import Cookies from "js-cookie";

const useDeleteUser = () => {
    const token = Cookies.get('token');

    return async (id: number) => {
        const header = {
            Authorization: `Bearer ${token}`
        }
        try {
            if (id) {
                const response = await axios.delete(`http://dam.spiritofweb.fr:8741/api/users/${id}`, {headers:header});
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export default useDeleteUser;
