import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BackPage from "../Button/BackPage";
import MetadataDisplay from "./MetadataDisplay";
import VersionDropdown from "./VersionDropdown";


const OneAsset = ({ asset, setAsset, versions, user }) => {
  const location = useLocation();
  const [selectedVersion, setSelectedVersion] = useState(null);

  const isTeamVideo =
  user.roles.includes("ROLE_EQUIPE_VIDEO");

  // Fonction appelée lorsque l'utilisateur sélectionne une version dans le menu déroulant
  const handleVersionChange = (e) => {
    //On récupère l'id de la version selectionner par l'user
    const selectedVersionId = parseInt(e.target.value);
    
    // On chercher la version qui correspond à l'id
    const selectedVersion = versions.find(
      (version) => version.id === selectedVersionId
    );
  
    // Si une version correspondante a été trouvée
    if (selectedVersion) {
      // on met à jour les méta de l'asset en fonction de la version choisi
      setAsset((prevAsset) => ({
        ...prevAsset,
        metadata: selectedVersion.metadata,
      }));
      
      // defini la version sélectionnée
      setSelectedVersion(selectedVersion);
    }
  };
  
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-6">
          <BackPage inBtn="&larr; Retournez au choix de l'asset" />
        </div>
        <div className="text-end col-6">
          <Link to={`${location.pathname}/comments`} className="btn border">
            Voir les commentaires sur cette asset
          </Link>
        </div>
      </div>

      <h1 className="text-center">Nom de l'asset : {asset.filename} </h1>

      {isTeamVideo && (
        <div className="text-center">
          <Link
            to={`${location.pathname}/add-video`}
            className="btn btn-info my-2"
          >
            Ajouter une nouvelle version vidéo
          </Link>
        </div>
      )}

      <div className="text-center">
        <Link
          to={`${location.pathname}/stockage`}
          className="btn btn-warning border"
        >
          Voir les documents lié à cette asset
        </Link>
      </div>

      <div>
        <p>Catégorie : {asset.category.name}</p>
      </div>
      
      {/* Choix de la version */}
      <VersionDropdown versions={versions} onChange={handleVersionChange} />

      {/* Affichage des méta en fonction */}
      {selectedVersion && (
        <MetadataDisplay version={selectedVersion} isTeamVideo={isTeamVideo} />
      )}
    </React.Fragment>
  );
};

export default OneAsset;
