import React, { useEffect, useState } from 'react';
import usePostDocument from "../../hook/usePostDocument";
import useGetAllCategories from "../../hook/useGetAllCategories";
import {DragNDropPropsInterface} from "../../interface/DragNDropInterface";
import '../../pages/App.css';


export default function DragNDrop({ projectId, assetId }: DragNDropPropsInterface) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { categories } = useGetAllCategories();
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const [tagName, setTagName] = useState<string>('');

    const postDocument = usePostDocument();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCategoryId = parseInt(event.target.value, 10);
        setSelectedCategory(selectedCategoryId);
    };

    const handleTagNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTagName(event.target.value);
    };

    const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        if (file) {
            setSelectedFile(file);
        }
    };

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!selectedFile || !selectedCategory || !tagName) {
            alert('Veuillez remplir tous les champs.');
            return;
        }

        try {
            await postDocument(projectId, assetId, selectedFile.name, selectedFile, selectedCategory, tagName);
            // Succès de l'envoi

        } catch (error) {
            // Gestion des erreurs
            alert('Une erreur est survenue lors de l\'envoi du document.');
            console.error(error);
        }


        // Réinitialiser les champs après l'envoi
        setSelectedFile(null);
        setSelectedCategory(null);
        setTagName('');
    };

    return (
        <div>
            <h2 style={{textAlign: 'center'}}>Upload document</h2>
            <form style={{marginLeft : '4rem'}} onSubmit={handleFormSubmit}>
                <div>
                    <label>Tag du document spécifique:</label>
                    <input type="text" value={tagName} onChange={handleTagNameChange} />
                </div>
                <br></br>
                <div>
                    <label>Category du document:</label>
                    <select value={selectedCategory || ''} onChange={handleCategoryChange}>
                        <option value="">Sélectionner une catégorie</option>
                        {categories && categories.length > 0 &&
                            categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                    </select>
                </div>
                <div
                    style={{
                        width: '24rem',
                        height: '10rem',
                        border: '0.1rem dashed #FBBD42',
                        textAlign: 'center',
                        marginTop: '1rem',
                        paddingTop: '4rem',
                        marginBottom: '1rem',
                    }}
                    onDragOver={(event) => event.preventDefault()}
                    onDrop={handleFileDrop}
                >
                    {selectedFile ? selectedFile.name : 'Faites glisser un fichier ici'}
                </div>
                <button className='btnSend' type="submit">Envoyer le document</button>
            </form>
        </div>
    );
}
