import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from "js-cookie";
import {DocumentData} from "../interface/DocumentInterface";

const useGetDocumentByAsset = (assetId: number, projectId: number) => {
    const [documents, setDocuments] = useState<DocumentData[] | null>(null);


    const token = Cookies.get('token');
    useEffect(() => {
        const fetchDocuments = async (projectId: number, assetId: number) => {
            const header = {
                Authorization: `Bearer ${token}`
            }
            try {
                if (assetId && projectId) {
                    const response = await axios.get(`http://dam.spiritofweb.fr:8741/api/project/${projectId}/assets/${assetId}/documents`,{headers: header})
                    setDocuments(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchDocuments(projectId, assetId);
    }, [projectId, assetId]);

    return {
        documents,
    };
};

export default useGetDocumentByAsset;