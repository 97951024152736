import React from "react";
import { Link, useNavigate } from "react-router-dom";
import '../../pages/App.css';
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import Cookies from "js-cookie";

export default function Sidebar({ onMenuItemClick }) {
    const [user, setUser, getUserInfo] = useContext(UserContext);
    const navigate = useNavigate();
    const handleLogout = () => {
        Cookies.remove('token');
        const url = "/";
        navigate(url);
    };

    return (
        <nav className="sideB col-4">
            <div className="features mt-5">
                <Link onClick={() => onMenuItemClick("info")}>Info</Link>
            </div>
            <div className="features mt-5">
                <Link onClick={() => onMenuItemClick("setting")}>Setting</Link>
            </div>
            {user && user.roles.includes("ROLE_CHEF_PROJET") && (
                <div className="features mt-5">
                    <Link to={"/admin/dashboard/"}>Gestion Projets</Link>
                </div>
            )}
            {user && user.roles.includes("ROLE_SUPER_ADMIN") && (
                <div className="features mt-5">
                    <Link to={"/super-admin"}>Administration</Link>
                </div>
            )}
            <div className="features mt-5">
                <Link onClick={handleLogout}>Déconnexion</Link>
            </div>
        </nav>
    )
}
