import React from "react";
import {Outlet, Link, useNavigate} from "react-router-dom";
import '../../pages/App.css';
import axios from "axios";
import { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import Cookies from "js-cookie";
import Home from "../../pages/home";


export default function Sidebar({ onMenuItemClick }) {
    const [user, setUser, getUserInfo] = useContext(UserContext);
    const [role, setRole] = useState("");
    const navigate = useNavigate();
    const handleLogout = () => {
        Cookies.remove('token');
        const url = "/";
        navigate(url);
        // Autres actions de déconnexion ou redirection ici
    };


    const project = () => {
        const url = "/my-project";
        navigate(url);
        // Autres actions de déconnexion ou redirection ici
    };


   return(
   <nav className="sideB col-4">
    {/* Si le role est Admin */}

       <div className="features mt-5">
           <Link  to={"/"}>Mes Projets</Link>
       </div>

       <div className="features mt-5">
           <Link onClick={() => onMenuItemClick("user")}>User</Link>
       </div>

       <div className="features mt-5">
           <Link onClick={() => onMenuItemClick("team")}>Team</Link>
       </div>

       <div className="features mt-5">
           <Link to={"/profile"}>Profil</Link>
       </div>

       <div className="features mt-5">
           <Link onClick={handleLogout}>Déconnexion</Link>
       </div>
    </nav>
    )
}