import { DragNDropPropsInterface } from "../../interface/DragNDropInterface";
import { useState } from "react";
import { DocumentData } from "../../interface/DocumentInterface";
import useGetDocumentByAsset from "../../hook/useGetDocumentsByAsset";
import useDeleteDocument from "../../hook/useDeleteDocument";
import useDeleteFileUpload from "../../hook/useDeleteFileUpload";
import React from "react";

export default function DeleteDocument({
  assetId,
  projectId,
}: DragNDropPropsInterface) {
  const { documents } = useGetDocumentByAsset(assetId, projectId);
  const deleteDocument = useDeleteDocument();
  const deleteFileUpload = useDeleteFileUpload();
  const [selectedDocument, setSelectedDocument] =
    useState<DocumentData | null>(null);

  const handleDocumentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    const selectedDoc = documents?.find(
      (doc: DocumentData) => doc.id === parseInt(selectedId, 10)
    );
    setSelectedDocument(selectedDoc || null);
  };

  const handleDelete = async () => {
    try {
      if (selectedDocument && selectedDocument.id && selectedDocument.name) {
        try {
          // Suppression dans la base de données
          await deleteDocument(selectedDocument.id);
          // Suppression dans le dossier de téléchargement
          await deleteFileUpload(selectedDocument.name);
          alert(`Document avec le nom ${selectedDocument.name} supprimé avec succès.`);
        } catch (error) {
          console.error(error);
          alert(`Document avec le nom ${selectedDocument.name} n'a pas pu être supprimé.`);
        }

        // Vous pouvez également mettre à jour la liste des documents après la suppression en appelant à nouveau le hook useGetDocumentByAsset.
        setSelectedDocument(null); // Réinitialiser la sélection après la suppression
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Supprimer des documents</h2>
      <div className="row">
        <div className="col-md-6">
          <select
            className="form-select"
            value={selectedDocument ? selectedDocument.id : ''}
            onChange={handleDocumentChange}
          >
            <option value="">Sélectionner un document à supprimer</option>
            {documents &&
              documents.map((document: DocumentData) => (
                <option key={document.id} value={document.id}>
                  {document.name}
                </option>
              ))}
          </select>
        </div>
        <div className="col-md-6">
          {selectedDocument && (
            <div>
              <button className="btn btn-danger" onClick={handleDelete}>Supprimer</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
