import { useEffect, useState } from 'react';
import axios from 'axios';
import {Team} from "../interface/TeamInterface";
import Cookies from "js-cookie";

const useGetTeam = (id: string | undefined) => {
    const [team, setTeam] = useState<Team | null>(null);
    const token = Cookies.get('token');
    useEffect(() => {
        const fetchTeam = async () => {
            const header = {
                Authorization: `Bearer ${token}`
            }
            try {
                if (id) {
                    const response = await axios.get('http://dam.spiritofweb.fr:8741/api/teams/' + id, {headers: header})
                    setTeam(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchTeam();
    }, [id]);

    return {
        team,
    };
};

export default useGetTeam;