import React, {useContext, useState} from "react";
import { UserContext } from "../../context/UserContext";
import useGetTeam from "../../hook/useGetTeam";
import useGetIcon from "../../hook/useGetIcon";

export default function Profil() {
    const [user] = useContext(UserContext);

    let teamId = null;
    if (user.teams && user.teams.length > 0) {
        teamId = user.teams[0];
    }

    const { team } = useGetTeam(teamId ? teamId.id : null); //

    const formatRole = (role) => {
        return role.replace("ROLE_", "");
    };

    return (
        <div className="col d-flex align-items-center flex-column h-auto mb-5">
            <img src={user.icon.url} alt="Logo la Saline Academy" style={{ width: '89px', marginTop: '40px' }} className="rounded-circle" />
            <h1 style={{ marginTop: '40px' }}>Mes informations</h1>
            <div className="w-75 mb-3">
                <div className="border border-warning rounded-4 text-center mb-3 p-2">
                    Prénom : {user.firstName}
                </div>
                <div className="border border-warning rounded-4 text-center p-2">
                    Nom : {user.lastName}
                </div>
            </div>
            <div className="w-75 mb-3">
                <div className="border border-warning rounded-4 text-center p-2">
                    Mail : {user.email}
                </div>
            </div>
            <div className="w-75 mb-3">
                {user.roles && (
                    <div className="border border-warning rounded-4 text-center p-2">
                        Rôles : {user.roles.filter(role => role !== "ROLE_USER").map(formatRole).join(", ")}
                    </div>
                )}
            </div>
            <div className="w-75">
                {team && (
                    <div className="border border-warning rounded-4 text-center p-2">
                        Équipe : {team.name}
                    </div>
                )}
            </div>
        </div>
    );
}