import React, {createContext, useState, useEffect} from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";


export const UserContext = createContext();
UserContext.displayName = "UserContext";

export default function UserProvider(props) {
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const token = Cookies.get("token");
  const navigate = useNavigate();

  const getUserInfo = async () => {
    if (!token) {
      setIsLoading(false);
      navigate("/");
    } else {
      try {
        const response = await axios.get(
          "http://dam.spiritofweb.fr:8741/api/info-user",
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setUser(response.data);
        setIsLoading(false);
      } catch (error) {
        navigate("/");
      }
    }
  };

  useEffect(() => { 
    getUserInfo();
  }, [token]);

  return isLoading ? (
    <div
    style={{
      fontSize: '24px',         // Taille de la police
      fontWeight: 'bold',      // Gras
      color: '#161C3D',        // Couleur du texte (par exemple, bleu)
      textAlign: 'center',     // Centrer le texte horizontalement
      marginTop: '20px'        // Marge supérieure de 20 pixels
    }}
    >En cours de chargement...</div>
  ) : (
    <UserContext.Provider value={[user, setUser, getUserInfo]}>
      {props.children}
    </UserContext.Provider>
  );
}
