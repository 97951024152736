import axios from 'axios';
import Cookies from "js-cookie";

const useDeleteProject = () => {
    const token = Cookies.get('token');

    const deleteProject = async (projectId: number) => {
        const header = {
            Authorization: `Bearer ${token}`
        }
        try {
            if (projectId) {
                const response = await axios.delete(`http://dam.spiritofweb.fr:8741/api/projects/${projectId}`,{headers:header});
            }
        } catch (error) {
            console.error(error);
        }
    };

    return deleteProject;
};

export default useDeleteProject;
