import React from "react";
import useGetTeam from "../../../hook/useGetTeam";
import useDeleteUserFromTeamById from "../../../hook/useDeleteUserFromTeamById";
import { DeleteUserTeamPropsInterface } from "../../../interface/DeleteUserTeamPropsInterface";

export default function DeleUserFromTeam({ teamId }: DeleteUserTeamPropsInterface) {
    const { team: teamData } = useGetTeam(teamId);
    const deleteUserFromTeam = useDeleteUserFromTeamById();

    const handleDeleteUser = (userId: number) => {
        deleteUserFromTeam(Number(teamId), userId);
    };

    return (
        <div className="container mt-4">
            {teamData && (
                <div>
                    <h3>Liste des users de la team :</h3>
                    {teamData.users.map((user) => (
                        <div className="d-flex align-items-center justify-content-between mb-2" key={user.id}>
                            <span>{user.lastName} {user.firstName}</span>
                            <button className="btn btn-danger" onClick={() => handleDeleteUser(user.id)}>Delete</button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}