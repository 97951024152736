import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from "js-cookie";
import {Teamtab} from "../interface/AllTeamInterface";

const useGetAllTeam = () => {
    const [teams, setTeams] = useState<Teamtab[] | null>(null);
    const token = Cookies.get('token');

    useEffect(() => {
        const fetchTeams = async () => {
            const header = {
                Authorization: `Bearer ${token}`
            };

            try {
                const response = await axios.get('http://dam.spiritofweb.fr:8741/api/teams', { headers: header });
                setTeams(response.data['hydra:member']);
            } catch (error) {
                console.error(error);
            }
        };

        fetchTeams();
    }, [token]);



    return {
        teams,
    };
};

export default useGetAllTeam;