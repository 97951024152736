import axios from 'axios';
import Cookies from "js-cookie";

const usePostAsset = () => {
    const token = Cookies.get('token');
    return async (filename: string,
                             projectId: number,
                             categoryId: number,
                             numb: number,) => {
        const header = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
        try {
            if (filename && projectId && categoryId && numb) {
                const response = await axios.post('http://dam.spiritofweb.fr:8741/api/assets', {
                    filename: filename,
                    status: "draft",
                    project: "/api/projects/" + projectId,
                    comment: [],
                    category: "/api/category_assets/" + categoryId,
                    version: [],
                    document: [],
                    numb: numb
                }, {headers: header});
            }
        } catch (error) {
            console.error(error);
        }
    };

};

export default usePostAsset;
