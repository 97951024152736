import React from "react";
import { DeleteAssetInterface } from "../../../interface/DeleteAssetInterface";
import useDeleteAsset from "../../../hook/useDeleteAsset";
import useGetProjectById from "../../../hook/useGetIdProjectById";
import useUpdateAssetStatus from "../../../hook/useUpdateAssetStatus";

export default function DeleteAssetFromProject({ projectId }: DeleteAssetInterface) {
    const { project } = useGetProjectById(Number(projectId));
    const { deleteAsset } = useDeleteAsset();
    const updateAssetStatus  = useUpdateAssetStatus();

    const handleDelete = (assetId: number) => {
        deleteAsset(assetId);
    };

    const handleStatusChange = (assetId: number, newStatus: string) => {
        updateAssetStatus(assetId, newStatus);
    };

    if (!project) {
        return (
            <div className="container mt-4 text-center">
                <h2>Assets du Projet :</h2>
                <div className="d-flex justify-content-center align-items-center loading-spinner">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden" 
                        style={{
                            fontSize: '24px',         // Taille de la police
                            fontWeight: 'bold',      // Gras
                            color: '#161C3D',        // Couleur du texte (par exemple, bleu)
                            textAlign: 'center',     // Centrer le texte horizontalement
                            marginTop: '20px'        // Marge supérieure de 20 pixels
                          }}>En cours de chargement...</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container mt-4">
            <h2>Assets du Projet :</h2>
            {project.asset.length === 0 ? (
                <p>Pas d'asset</p>
            ) : (
                project.asset.map((asset) => (
                    <div className="card mt-3" key={asset.id}>
                        <div className="card-body">
                            <h5 className="card-title">{asset.filename}</h5>
                            <p className="card-text">Status de l'asset : {asset.status}</p>
                            <div className="mb-3">
                                <label htmlFor={`status-${asset.id}`} className="form-label">Changement de status:</label>
                                <select
                                    id={`status-${asset.id}`}
                                    className="form-select"
                                    value={asset.status}
                                    onChange={(e) => handleStatusChange(asset.id, e.target.value)}
                                >
                                    <option value="draft">Draft</option>
                                    <option value="pending_approval">Pending Approval</option>
                                    <option value="rejected">Rejected</option>
                                    <option value="approved">Approved</option>
                                    <option value="archived">Archived</option>
                                    <option value="published">Published</option>
                                </select>
                            </div>
                            <button
                                className="btn btn-danger"
                                onClick={() => handleDelete(asset.id)}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};
