import React, { useContext, useState, useEffect } from "react";
import Login from "../security/Login";
import { useNavigate } from "react-router-dom";
import "./App.css";
import axios from "axios";
import Cookies from "js-cookie";
import { UserContext } from "../context/UserContext";
import Logo from "../public/Logo 150X150-blanc.png";

export default function Home() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  const expirationTime = new Date();
  expirationTime.setHours(expirationTime.getHours() + 1);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      axios.defaults.headers.post["Content-Type"] = "application/json";
      const response = await axios.post(
        "http://dam.spiritofweb.fr:8741/api/login",
        JSON.stringify({ email: email, password: password })
      );
      setSuccess(true);
      /*
       */ Cookies.set("token", response.data.token, {
        expires: expirationTime,
      });
      navigate("/my-project");

    } catch (error) {
      setError("Email ou mot de passe invalide");
    }
  };

  useEffect(() => {
    if (success || Cookies.get("token")) {
      navigate("/my-project");
    }
  }, [success]); // Écoutez uniquement les changements de 'success'

  return (
    <React.Fragment>
      <div className="loginPage">
        <div className="position-absolute w-100 top-50 translate-middle start-50">
          <div className="text-center">
            <img
              src={Logo}
              alt="logo"
              style={{ width: "150px", marginBottom: "50px" }}
            />
          </div>
          <Login
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            onSubmit={onSubmit}
            error={error}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
