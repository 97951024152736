import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useGetTeam from "../hook/useGetTeam";
import useDeleteUserFromTeamById from "../hook/useDeleteUserFromTeamById";
import FormAddUserinTeam from "../components/Admin/Team/FormAddUserinTeam";
import DeleteProject from "../components/Admin/Projet/DeleteProject";
import DeleteAssetFromProject from "../components/Admin/Asset/DeleteAssetFromProject";
import FormCreateAsset from "../components/Admin/Asset/FormCreateAsset";
import Sidebar from "../components/Sidebar/specificChefProjet";
import DeleUserFromTeam from "../components/Admin/Team/DeleUserFromTeam";
import AssetChefProjet from "../components/Admin/Asset/AssetChefProjet";
import ProjectChefProjet from "../components/Admin/Projet/ProjectChefProjet";
import TeamChefProjet from "../components/Admin/Team/TeamChefProjet";

export default function SpecificDashboard() {
  const { team, project } = useParams();
  const { team: teamData } = useGetTeam(team);
  const [activeMenuItem, setActiveMenuItem] = useState("assets");

  const handleMenuItemClick = (item: React.SetStateAction<string>) => {
    setActiveMenuItem(item);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="col-12">
          <div className="row">
            <Sidebar onMenuItemClick={handleMenuItemClick} />
            <div className="col-8">
              <h2 className="text-center mt-4 mb-4">
                Dashboard Chef de projet : {teamData?.name}
              </h2>
              <p className="mb-3 fw-bold">Projet : {project}</p>
              {activeMenuItem === "team" && <TeamChefProjet team={team} />}
              {activeMenuItem === "projets" && (
                <ProjectChefProjet project={project} />
              )}
              {activeMenuItem === "assets" && (
                <AssetChefProjet project={project} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
