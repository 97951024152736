import React, { useState } from "react";
import { DeleteProjectInterface } from "../../../interface/DeleteProjectInterface";
import usePostAsset from "../../../hook/usePostAsset";

export default function FormCreateAsset({ projectId }: DeleteProjectInterface) {
    const [filename, setFilename] = useState<string>("nom de l'asset");
    const [numb, setNumb] = useState<number>(1.0);
    const [type, setType] = useState<number>(2);

    const postAsset = usePostAsset();

    const handleChangeFileName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilename(event.target.value);
    };

    const handleChangeNumb = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        setNumb(value);
    };

    const handleChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        switch (event.target.value) {
            case "1":
                setType(2);
                break;
            case "2":
                setType(3);
                break;
            default:
                setType(2);
                break;
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            postAsset(filename, Number(projectId), type, numb);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="container mt-4">
            <h2>Ajouter un asset au projet</h2>
            <div className="mb-3">
                <label className="form-label">Filename:</label>
                <input type="text" className="form-control" name="filename" value={filename} onChange={handleChangeFileName} />
            </div>
            <div className="mb-3">
                <label className="form-label">Version:</label>
                <input type="number" className="form-control" name="numb" value={numb} step={0.1} onChange={handleChangeNumb} />
            </div>
            <div className="mb-3">
                <label className="form-label">Type de l'asset:</label>
                <select className="form-select" value={type} onChange={handleChangeType}>
                    <option value="2">Video</option>
                    <option value="3">Image</option>
                </select>
            </div>
            <button type="submit" className="btn btn-primary">Créer Asset</button>
        </form>
    );
};