import React, {useState} from "react";
import {Usertab} from "../../../interface/AllUserInterface";
import useGetAllTeam from "../../../hook/useGetAllTeam";
import {Teamtab} from "../../../interface/AllTeamInterface";
import useDeleteTeam from "../../../hook/useDeleteTeam";

export default function DeleteTeam() {
    const { teams } = useGetAllTeam();
    const deleteTeam = useDeleteTeam();
    const [selectedTeam, setSelectedTeam] = useState<Teamtab | null>(null);

    const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedTeamId = event.target.value;
        const selectedTeam = teams?.find(team => team.id === Number(selectedTeamId)) || null;
        setSelectedTeam(selectedTeam);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (selectedTeam) {
            deleteTeam(selectedTeam.id);
        }
    };

    return (
        <div className="container mt-4">
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <h2>Delete Team</h2>
                    <label htmlFor="team" className="form-label">Select Team:</label>
                    <select className="form-control" id="team" value={selectedTeam?.id || ''} onChange={handleUserChange}>
                        {teams && teams.length > 0 &&
                            teams.map((team: Teamtab) => (
                                <option key={team.id} value={team.id}>
                                    {team.name}
                                </option>
                            ))}
                    </select>
                </div>
                <button type="submit" className="btn btn-danger">Delete</button>
            </form>
        </div>
    );
};