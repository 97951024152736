import React, {useContext, useEffect, useState} from "react";
import usePatchUser from "../../../hook/usePatchUser";
import {UserContext} from "../../../context/UserContext";
import {UserData} from "../../../interface/UserDataInterface";
import useGetAllIcon from "../../../hook/useGetAllIcon";
import './style.css'
import {ProfilProps} from "../../../interface/User";

export default function UpdateProfile({userId}: ProfilProps) {
    const patchUser = usePatchUser();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const { icons } = useGetAllIcon();
    const [description, setDescription] = useState("");
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [selectedIconId, setSelectedIconId] = useState<number | null>(null); // ID de l'icône sélectionné


    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };


    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    };


    // const roleOptions = {
    //     ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
    //     ROLE_CHEF_PROJET: 'ROLE_CHEF_PROJET',
    //     ROLE_EQUIPE_VIDEO: 'ROLE_EQUIPE_VIDEO',
    //     ROLE_ADMIN: 'ROLE_ADMIN',
    //     ROLE_REDACTEUR: 'ROLE_REDACTEUR',
    //     ROLE_PROFESSEUR: 'ROLE_PROFESSEUR',
    //     ROLE_TRADUCTEUR:  'ROLE_TRADUCTEUR',
    //     ROLE_REGISSEUR: 'ROLE_REGISSEUR',
    //     ROLE_MONTEUR: 'ROLE_MONTEUR',
    //     ROLE_CADREUR: 'ROLE_CADREUR',
    //     ROLE_MIXEUR: 'ROLE_MIXEUR',
    //     ROLE_ECLAIREUR:  'ROLE_ECLAIREUR',
    //     ROLE_REAL: 'ROLE_REAL',
    //     ROLE_SON: 'ROLE_SON',
    // };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (userId) {
            const dataToUpdate: UserData = {};
            if (firstName !== "") dataToUpdate.firstName = firstName;
            if (lastName !== "") dataToUpdate.lastName = lastName;
            if (email !== "") dataToUpdate.email = email;
            if (selectedIconId !== null) dataToUpdate.icon = "/api/icons/" + selectedIconId;
            if (description !== "") dataToUpdate.description = description;

            await patchUser(userId, dataToUpdate);
            setUpdateSuccess(true);
        }
    };

    return (
        <div className="container mt-4">
            <h2>Update Profile</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="prenom" className="form-label">Prénom:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="prenom"
                        name="prenom"
                        value={firstName}
                        onChange={handleFirstNameChange}
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="nom" className="form-label">Nom:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="nom"
                        name="nom"
                        value={lastName}
                        onChange={handleLastNameChange}
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email:</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </div>

                {icons && icons.length > 0 ? (
                    <div className="mb-3">
                        <label htmlFor="icon" className="form-label">Choisir une icône:</label>
                        <div className="row">
                            {icons.map((icon) => (
                                <div
                                    key={icon.id}
                                    className={`col-3 text-center ${selectedIconId === icon.id ? 'border border-primary' : ''}`}
                                    onClick={() => setSelectedIconId(icon.id)}
                                >
                                    <img src={icon.url} alt={`Icon ${icon.id}`} className="img-fluid icon-img" />
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div
                    style={{
                        fontSize: '24px',         // Taille de la police
                        fontWeight: 'bold',      // Gras
                        color: '#161C3D',        // Couleur du texte (par exemple, bleu)
                        textAlign: 'center',     // Centrer le texte horizontalement
                        marginTop: '20px'        // Marge supérieure de 20 pixels
                      }}
                    >Chargement des icônes...</div>
                )}


                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description:</label>
                    <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        value={description}
                        onChange={handleDescriptionChange}
                    />
                </div>

                <div>
                    <button type="submit" className="btn btn-primary">
                        Mettre à jour
                    </button>
                </div>
            </form>
            {updateSuccess &&
                <div className="alert alert-success mt-3">
                    Profil modifié !
                </div>
            }
        </div>
    );
};