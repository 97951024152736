import React, {useContext} from "react";
import {AdminRouteProps} from "../../interface/AdminRouteProps";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function AdminRoute({ isAdmin, children }: AdminRouteProps) {
    const navigate = useNavigate();


    useEffect(() => {
        const checkUserRole = async () => {
            if (!isAdmin) {
                navigate('/error');
            }
        };

        checkUserRole();
    }, [isAdmin, navigate]);

    if (isAdmin) {
        return children;
    } else {
        return null;
    }
}