import React from "react";

const LineSteps = ({ steps2 }) => {
    /* Gestion des steps de la line */
    /* On défini une variable avec les class "non actif" de la li, si steps2 est appeler, on ajoute la classe active */
  let step2Class = "step0 text-center";
  if (steps2) {
    step2Class += " active";
  }



  return (
    <React.Fragment>
      <div className="progress-track mx-auto">
        <ul id="progressbar">
            
          <li className="step0 active" id="step1">
            Lien vidéo
          </li>
          <li className={step2Class} id="step2">
            Métadata
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default LineSteps;
