import React, { useState } from "react";
import { CreateProjectPropsInterface } from "../../../interface/CreateProjectPropsInterface";
import usePostProject from "../../../hook/usePostProject";
import { Team } from "../../../interface/TeamInterface";

interface TeamData {
    id: number;
    name: string;
    // Autres propriétés d'équipe
}

export default function CreateProject({ teamsData }: { teamsData: TeamData[] }) {
    const [name, setName] = useState<string>('Nom du projet');
    const [sent, setSent] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [selectedTeamId, setSelectedTeamId] = useState<number>(0);
    const createProject = usePostProject();

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await createProject(name, selectedTeamId);
            setSent(true);
            setError(false);
        } catch (error) {
            console.error(error);
            setError(true);
            setSent(false);
        }
    };

    return (
        <div className="container mt-4">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-10">
                    <h2 className="text-center">Ajouter un nouveau projet à la team</h2>
                    <form onSubmit={handleFormSubmit} className="mt-3">
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Nom :</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <label className="form-label mt-3" htmlFor="team">Sélectionner une équipe :</label>
                            <select className="form-control" id="team" value={selectedTeamId} onChange={(e) => setSelectedTeamId(parseInt(e.target.value))}>
                                {teamsData.map((team, index) => (
                                    <option key={index} value={team.id}>
                                        {team.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button type="submit" className="btn btn-primary d-block mx-auto mt-3">
                            Ajouter
                        </button>
                    </form>
                    {sent && !error && (
                        <p style={{ color: 'green', marginTop: '1rem' }} className="text-center">
                            Projet ajouté avec succès !
                        </p>
                    )}
                    {error && !sent && (
                        <p style={{ color: 'red', marginTop: '1rem' }} className="text-center">
                            Une erreur s'est produite lors de l'ajout du projet.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}
