import {DeleteProjectInterface} from "../../../interface/DeleteProjectInterface";
import FormCreateAsset from "../Asset/FormCreateAsset";
import React, {useContext} from "react";
import DeleteAssetFromProject from "../Asset/DeleteAssetFromProject";
import DeleteProject from "./DeleteProject";
import {ChefProjetPropsInterface} from "../../../interface/ChefProjetPropsInterface";
import CreateProject from "./CreateProjet";
import {UserContext} from "../../../context/UserContext";

export default function ProjectChefProjet({ project }: ChefProjetPropsInterface) {
    return(
        <>
            <DeleteProject projectId={project}/>
        </>
    );
}