import axios from 'axios';
import Cookies from "js-cookie";

const useDeleteFileUpload = () => {
    const token = Cookies.get('token');
    return async (documentName: string) => {
        const header = {
            Authorization: `Bearer ${token}`
        }
        try {
            if (documentName) {
                const response = await axios.delete(`http://dam.spiritofweb.fr:8741/api/delete-document/${documentName}`, {headers: header});

            }
        } catch (error) {
            console.error(error);
        }
    };
};

export default useDeleteFileUpload;
