import axios from 'axios';
import Cookies from "js-cookie";
import {useContext} from "react";
import {UserContext} from "../context/UserContext";


const usePostTeam = () => {
    const token = Cookies.get('token');
    let user = useContext(UserContext);

    return async (name: string) => {
        const header = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
        try {
            if (name) {
                const response = await axios.post('http://dam.spiritofweb.fr:8741/createTeam', {
                    name: name,
                    users: [],
                    projects: []
                }, {headers: header});
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export default usePostTeam;
