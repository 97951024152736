import { useEffect, useState} from 'react';
import axios from 'axios';
import {Project} from "../interface/TeamInterface";
import Cookies from 'js-cookie';

const useGetProjectByTeamId = (id: number | null) => {
    const [allProject, setAllProject] = useState<Project[] | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const token = Cookies.get('token');
    useEffect(() => {
        const fetchProjectId = async () => {
            const header = {
                Authorization: `Bearer ${token}`
            }
            try {
                    const response = await axios.get(`http://dam.spiritofweb.fr:8741/api/teams/${id}`,{headers: header});
                    setAllProject(response.data.projects);
                    setIsLoading(false);
            } catch (error) {
                console.error(error)
                setIsLoading(false);
            }
        };

        if (id != null) {
            setIsLoading(true); // La requête commence, isLoading devient true
            fetchProjectId();
        }
    }, [id]);

    return {
        allProject,
        isLoading,
    };
};

export default useGetProjectByTeamId;