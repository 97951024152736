import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";

const AssetsChoice = ({ assets, setAssets }) => {
  const { idProject } = useParams();

  const location = useLocation();
  return (
    <React.Fragment>
      <h2 className="text-center">Nos assets du projet</h2>

      <div>
        {assets && assets.length > 0 ? (
          assets.map((asset) => {
            return (
              <React.Fragment key={asset.id}>
                <div className="card mb-3">
                  <h5 className="card-header"> {asset.filename}</h5>
                  <div className="card-body">
                    <h5 className="card-title">Status : {asset.status}</h5>
                    <Link
                      to={{
                        pathname: `${location.pathname}/${asset.id}`,
                      }}
                    >
                      Voir cette assets
                    </Link>
                  </div>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <p className="text-center">Aucun asset n'est disponible pour ce projet</p>
        )}
      </div>
    </React.Fragment>
  );
};

export default AssetsChoice;
