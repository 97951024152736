import axios from 'axios';
import Cookies from "js-cookie";

const usePostDocument = () => {
    const token = Cookies.get('token');
    return async (
                  projectId: number,
                  assetId: number,
                  name: string,
                  url: File,
                  category: number,
                  tag: string,) => {
        const header = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
        try {
            if (projectId && assetId && name && url && category && tag) {
                const response = await axios.post(`http://dam.spiritofweb.fr:8741/api/project/${projectId}/assets/${assetId}/documents/upload`, {
                    name: name,
                    url: url,
                    category: category,
                    tag: tag
                }, {headers: header});
            }
        } catch (error) {
            console.error(error);
        }
    };

};

export default usePostDocument;
