import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import axios from "axios";
import Cookies from "js-cookie";
import Sidebar from "../components/Sidebar";
import { useParams } from "react-router-dom";
import OneAsset from "../components/OneAsset";
import { UserContext } from "../context/UserContext";

const Assets = () => {
  const [asset, setAsset] = useState();
  const [versions, setVersions] = useState([]); // Ajout de l'état pour les versions
  const { projectId } = useParams();
  const { assetId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser, getUserInfo] = useContext(UserContext);

  const fetchDataAssets = async () => {
    const token = Cookies.get("token");
    const response = await axios.get(
      `http://dam.spiritofweb.fr:8741/api/project/${projectId}/assets/${assetId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseVersion = await axios.get(
      `http://dam.spiritofweb.fr:8741/api/project/${projectId}/assets/${assetId}/versionings`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setAsset(response.data);
    setVersions(responseVersion.data); // Met à jour les versions

    setIsLoading(false);
  };

  useEffect(() => {
    fetchDataAssets();
  }, []);

  return isLoading ? (
    <React.Fragment>
      <p
        style={{
          fontSize: "24px", // Taille de la police
          fontWeight: "bold", // Gras
          color: "#161C3D", // Couleur du texte (par exemple, bleu)
          textAlign: "center", // Centrer le texte horizontalement
          marginTop: "20px", // Marge supérieure de 20 pixels
        }}
      >
        En cours de chargement
      </p>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="container-fluid">
        <div className="col-12">
          <div className="row">
            <Sidebar></Sidebar>
            <div className="col-8">
              <OneAsset
                asset={asset}
                setAsset={setAsset}
                versions={versions}
                user={user}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Assets;
