import React from "react";
import Sidebar from "../components/Sidebar";
import Welcome from "../components/Welcome";
import './App.css';
import DragNDrop from "../components/Document/DragNDrop";
import { useParams } from "react-router-dom";
import { useState } from "react";

export default function Redacteur() {
  const { projectId } = useParams();
  const { assetId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  return isLoading ? (
    <React.Fragment>
      
        <p>En cours de chargement</p>
    
    </React.Fragment>
  ) : (
    <>
    <div className='container-fluid'>
          <div className='col-12'>
            <div className='row'>
            <Sidebar/>
            <Welcome/>
            <div className="d-flex align-items-center col-4 ">
                <div className="row">
                  <h3 className="text-center">Stockage commun</h3>
                  <div className="files">
                    <DragNDrop assetId={assetId} projectId={projectId}/>
                    </div>
                </div>  
              </div> 
            </div>
          </div>
        </div>
    </> 
    
  );
}