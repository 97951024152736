import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

const BackPage = ({ inBtn }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <button className="btn btn-dark" onClick={() => navigate(-1)}>
        {inBtn}
      </button>
    </React.Fragment>
  );
};

export default BackPage;
