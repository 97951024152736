import React, { useContext } from "react";
import DeleteProfile from "./Update/DeleteProfile";
import { ProfilProps } from "../../interface/User";
import Sidebar from "../Sidebar/profile";
import UpdateProfile from "./Update/UpdateProfil";
import {UserContext} from "../../context/UserContext";
import UpdatePassword from "./Update/UpdatePasword"; // Assurez-vous d'importer correctement le composant Sidebar depuis le bon emplacement

export default function Setting() {
    const [user] = useContext(UserContext);
    return (
        <>
            <UpdateProfile  userId={user.id}/>
            <UpdatePassword userId={user.id}/>
            <DeleteProfile />
        </>
    );
}
