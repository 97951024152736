import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import LineSteps from "./LineSteps";
import "./style.css";

const UploadVideoVersion = ({ handlePathSubmit, path, setPath }) => {
  const location = useLocation();

  return (
    <React.Fragment>
      <LineSteps />
      <div className="container">
        <h2 className="text-center">
          Ajouter une nouvelle version de la vidéo
        </h2>

        <form
          className="d-flex justify-content-center flex-column align-items-center mt-5 formStep1"
          onSubmit={handlePathSubmit}
        >
          <input
            className="w-50 rounded mb-3 border p-3 mb-5"
            required
            type="text"
            placeholder="lien de la vidéo ici"
            value={path}
            onChange={(e) => setPath(e.target.value)}
          />

          <button className="w-50 btn btn-outline-secondary" type="submit">
            Suivant
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};

export default UploadVideoVersion;
