import React from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import '../../pages/App.css';
import axios from "axios";
import { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import Cookies from "js-cookie";
import Home from "../../pages/home";

export default function Sidebar() {
    const [user, setUser, getUserInfo] = useContext(UserContext);
    const [role, setRole] = useState("");
    const navigate = useNavigate();
    const handleLogout = () => {
        Cookies.remove('token');
        const url = "/";
        navigate(url);
    };



    function transformRole(role) {
        return role.replace(/^ROLE_/, '').replace(/_/g, ' ');
    }

    const transformedRoles = user && user.roles ? user.roles.map(transformRole) : [];




    return (
        <nav className="sideB col-4">
            <div className="flex-column mt-5 text-white text-center">
                <p className="">{user.firstName}</p>
                <small>
                    {transformedRoles.join(', ')}
                </small>
            </div>
            <div className="features mt-5">
                <Link to={'/my-project'}>Mes Projets</Link>
            </div>
            {user && user.roles.includes("ROLE_CHEF_PROJET") && (
                <div className="features mt-5">
                    <Link to={"/admin/dashboard/"}>Gestion Projets</Link>
                </div>
            )}
            {user && user.roles.includes("ROLE_SUPER_ADMIN") && (
                <div className="features mt-5">
                    <Link to={"/super-admin"}>Administration</Link>
                </div>
            )}
            <div className="features mt-5">
                <Link to={"/profile"}>Profil</Link>
            </div>
            <div className="features mt-5">
                <Link onClick={handleLogout}>Déconnexion</Link>
            </div>

        </nav>
    )
}
