import React, {useContext} from "react";
import {UserContext} from "../../../context/UserContext";
import useDeleteUser from "../../../hook/useDeleteUserById";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";


export default function DeleteProfile() {
    // @ts-ignore
    const [user] = useContext(UserContext);
    const deleteUser = useDeleteUser();
    const navigate = useNavigate();

    const handleDelete = async () => {
        await deleteUser(user.id);
        Cookies.remove('token');
        const url = "/";
        navigate(url);
    };

    return (
        <div className="container mt-4">
            <h3>Supprimer le profil</h3>
            <div className="alert alert-danger">
                <p>Attention, vous êtes sur le point de supprimer votre profile. Cette action est irréversible.</p>
                <button className="btn btn-danger" onClick={handleDelete}>Supprimer le profil</button>
            </div>
        </div>
    );
};
