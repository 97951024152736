import React, { useState } from 'react';
import useGetAllUser from "../../../hook/useGetAllUser";
import useAddUserInTeamById from "../../../hook/useAddUserInTeamById";
import { FormAddUserTeamProps } from "../../../interface/FormAddUserTeamProps";
import { Usertab } from "../../../interface/AllUserInterface";

export default function FormAddUserinTeam({ team }: FormAddUserTeamProps) {
    const { users } = useGetAllUser();
    const addUserInTeam = useAddUserInTeamById();
    const [selectedUser, setSelectedUser] = useState<Usertab | null>(null);

    const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedUserId = event.target.value;
        const selectedUser = users?.find(user => user.id === Number(selectedUserId)) || null;
        setSelectedUser(selectedUser);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (selectedUser) {
            addUserInTeam(Number(team), selectedUser.id);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="container mt-4">
            <div className="mb-3">
                <h3>Ajouter un user exitant à la team</h3>
                <label htmlFor="user" className="form-label">Select User:</label>
                <select id="user" className="form-select" value={selectedUser?.id || ''} onChange={handleUserChange}>
                    {users && users.length > 0 &&
                        users.map((user: Usertab) => (
                            <option key={user.id} value={user.id}>
                                {user.lastName} {user.firstName}
                            </option>
                        ))}
                </select>
            </div>
            <button type="submit" className="btn btn-primary">Ajouter le user</button>
        </form>
    );
}
