// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#progressbar {
  margin: 3vh 0;
  overflow: hidden;
  color: #131c3d;
  padding-left: 0;
}

#progressbar li {
  list-style-type: none;
  font-size: 0.8rem;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
  color: #131c3d;
  text-align: center;
}

#progressbar li:before {
  content: "";
  color: #131c3d;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  line-height: 29px;
  display: block;
  font-size: 12px;
  background: rgb(151, 149, 149);
  border-radius: 50%;
  z-index: -1;
  margin-bottom: 1vh;
}

#progressbar li:after {
  content: "";
  height: 3px;
  background: rgb(151, 149, 149, 0.651);
  position: absolute;
  left: 0;
  right: 0;
  margin-bottom: 2vh;
  top: 8px;
  z-index: 1;
}

.progress-track {
  padding: 0 8%;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #131c3d;
}

#progressbar li:nth-child(3):after {
  width: 68%;
}

/* Formulaire */

.formStep1 {
  height: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/components/UploadVideoVersion/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,WAAW;EACX,YAAY;EACZ,cAAc;EACd,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,8BAA8B;EAC9B,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,qCAAqC;EACrC,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA,eAAe;;AAEf;EACE,aAAa;AACf","sourcesContent":["#progressbar {\n  margin: 3vh 0;\n  overflow: hidden;\n  color: #131c3d;\n  padding-left: 0;\n}\n\n#progressbar li {\n  list-style-type: none;\n  font-size: 0.8rem;\n  width: 25%;\n  float: left;\n  position: relative;\n  font-weight: 400;\n  color: #131c3d;\n  text-align: center;\n}\n\n#progressbar li:before {\n  content: \"\";\n  color: #131c3d;\n  width: 20px;\n  height: 20px;\n  margin: 0 auto;\n  line-height: 29px;\n  display: block;\n  font-size: 12px;\n  background: rgb(151, 149, 149);\n  border-radius: 50%;\n  z-index: -1;\n  margin-bottom: 1vh;\n}\n\n#progressbar li:after {\n  content: \"\";\n  height: 3px;\n  background: rgb(151, 149, 149, 0.651);\n  position: absolute;\n  left: 0;\n  right: 0;\n  margin-bottom: 2vh;\n  top: 8px;\n  z-index: 1;\n}\n\n.progress-track {\n  padding: 0 8%;\n}\n\n#progressbar li.active:before,\n#progressbar li.active:after {\n  background: #131c3d;\n}\n\n#progressbar li:nth-child(3):after {\n  width: 68%;\n}\n\n/* Formulaire */\n\n.formStep1 {\n  height: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
