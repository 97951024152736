import axios from 'axios';
import Cookies from "js-cookie";

const useUpdateAssetStatus = () => {

    const token = Cookies.get('token');
    return async (assetId: number, status: string) => {
        const header = {
            "Content-Type": "application/merge-patch+json",
            Authorization: `Bearer ${token}`
        }
        try {
            if (assetId && status) {
                const response = await axios.patch(`http://dam.spiritofweb.fr:8741/api/assets/${assetId}`, {
                    status: status
                }, {headers: header});
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export default useUpdateAssetStatus;
