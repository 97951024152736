import React from "react";
import {Outlet, Link, useNavigate} from "react-router-dom";
import '../../pages/App.css';
import Cookies from "js-cookie";


export default function Sidebar({ onMenuItemClick }) {
    const navigate = useNavigate();
    const handleLogout = () => {
        Cookies.remove('token');
        const url = "/";
        navigate(url);
        // Autres actions de déconnexion ou redirection ici
    };

   return(
       <nav className="sideB col-4">
           <div className="features mt-5">
               <Link  to={"/"}>Mes Projets</Link>
           </div>
           <div className="features mt-5">
               <Link onClick={() => onMenuItemClick("assets")}>Assets</Link>
           </div>
           <div className="features mt-5">
               <Link onClick={() => onMenuItemClick("projets")}>Projets</Link>
           </div>
           <div className="features mt-5">
               <Link onClick={() => onMenuItemClick("team")}>Team</Link>
           </div>

           <div className="features mt-5">
               <Link onClick={handleLogout}>Déconnexion</Link>
           </div>
       </nav>
    )
}