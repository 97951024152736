import React, {useState} from "react";
import FormCreateAsset from "../components/Admin/Asset/FormCreateAsset";
import DeleteUser from "../components/SuperAdmin/User/DeleteUser";
import DeleteTeam from "../components/SuperAdmin/Team/DeleteTeam";
import FormCreateTeam from "../components/SuperAdmin/Team/FormCreateTeam";
import DragNDrop from "../components/Document/DragNDrop";
import DeleteDocument from "../components/Document/DeleteDocument";
import Sidebar from "../components/Sidebar/superAdmin";
import FormCreateUser from "../components/SuperAdmin/User/FormCreateUser";
import TeamChefProjet from "../components/Admin/Team/TeamChefProjet";
import ProjectChefProjet from "../components/Admin/Projet/ProjectChefProjet";
import TeamSuperAdmin from "../components/SuperAdmin/Team/TeamSuperAdmin";
import UserSuperAdmin from "../components/SuperAdmin/User/UserSuperAdmin";



export default function SuperAdminDashboard() {
    const [activeMenuItem, setActiveMenuItem] = useState("user");
    const handleMenuItemClick = (item: React.SetStateAction<string>) => {
        setActiveMenuItem(item);
    };

    return(
        <>
            <div className="container-fluid">
                <div className="col-12">
                    <div className="row">
                        <Sidebar onMenuItemClick={handleMenuItemClick}/>
                        <div className="col-8">
                            {activeMenuItem === "team" && <TeamSuperAdmin/>}
                            {activeMenuItem === "user" && <UserSuperAdmin/>}
                        </div>
                     </div>
                </div>
            </div>
        </>
    );
}

