import axios from 'axios';
import Cookies from "js-cookie";

const useDeleteTeam = () => {
    const token = Cookies.get('token');

    const deleteTeam = async (teamId: number) => {
        const header = {
            Authorization: `Bearer ${token}`
        }
        try {
            if (teamId) {
                const response = await axios.delete(`http://dam.spiritofweb.fr:8741/api/teams/${teamId}`,{headers:header});
            }
        } catch (error) {
            console.error(error);
        }
    };

    return deleteTeam;
};

export default useDeleteTeam;
