import React from "react";
import { Link } from "react-router-dom";

const MyProject = ({ myProjects, setMyProjects }) => {
  return (
    <div>
      <h2 className="text-center"> Projets : </h2>
      {myProjects && myProjects.length > 0 ? (
        myProjects.map((project) => (
          <div key={project.id} className="p-1 border rounded bg-light my-5">
            <Link to={`/project/${project.id}/assets`}>
              <h3 className="text-dark"> - {project.name}</h3>
            </Link>
          </div>
        ))
      ) : (
        <p className="mx-auto my-5 p-3 border w-25">Vous n'avez pas encore de projet</p>
      )}
    </div>
  );
};

export default MyProject;
