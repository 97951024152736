import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import axios from "axios";
import Cookies from "js-cookie";
import MyProject from "../components/ProjectChoice";
import Sidebar from "../components/Sidebar";

const MyProjects = () => {
  const [myProjects, setMyProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchDataMyProject = async () => {
    const token = Cookies.get("token");
    const response = await axios.get("http://dam.spiritofweb.fr:8741/api/my-project", {
      headers: {
        Authorization: `Bearer ${token}`, // Inclut le JWT dans l'en-tête Authorization
      },
    });
    setMyProjects(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDataMyProject();
  }, []);

  return isLoading ? (
    <React.Fragment>
      <p
        style={{
          fontSize: "24px", // Taille de la police
          fontWeight: "bold", // Gras
          color: "#161C3D", // Couleur du texte (par exemple, bleu)
          textAlign: "center", // Centrer le texte horizontalement
          marginTop: "20px", // Marge supérieure de 20 pixels
        }}
      >
        En cours de chargement...
      </p>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="container-fluid">
        <div className="col-12">
          <div className="row">
            <Sidebar></Sidebar>
            <div className="col-8">
              <MyProject
                myProjects={myProjects}
                setMyProjects={setMyProjects}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyProjects;
