import React, { useState } from "react";
import LineSteps from "./LineSteps";

const MetadataVersion = ({ handleMetadataSubmit, metadata, setMetadata }) => {
  // GESTION DU TABLEAU MATÉRIAUX REQUISE
  const requireMatValue = metadata ? metadata.requireMat : [];

  /* on créer une contante qui va gérer l'état de requireMatValue , Afin de gérer ca valeur, qui est soit un tableau avec des mat soit un tableau vide  */
  const [requireMatInput, setRequireMatInput] = useState(
    requireMatValue
  );

  /* Stockage de la valeur au moment du changement  */
  const handleRequireMatChange = (e) => {
    setRequireMatInput(e.target.value);
  };

  /* Gestion lorsque l'user n'est plus focus sur l'input */
  const handleRequireMatBlur = () => {
    /* Si requireMatInput est une string */
    if (typeof requireMatInput === 'string') {

    /* On récupère la valeur de requireMatInput, On transfère le contenue en tableau en prenant comme séparateur la "," */
      const materials = requireMatInput
        .split(",")
      /* On boucle sur materials et on vire tous les espace blancs en trop */
        .map((material) => material.trim())
      /* On filtre tous les élèments vide du tableau */
        .filter((material) => material !== "");
  
      /* Met à jour les données de l'input avec le tableau valide*/
      setMetadata({
        ...metadata,
        requireMat: materials,
      });
    }
  };
  


  return (
    <React.Fragment>
      <LineSteps steps2 />

      <form
        className="d-flex justify-content-center flex-column align-items-center mt-5 formStep2 container "
        onSubmit={handleMetadataSubmit}
      >
        <div className="row">
          <div className="mb-3 col-md-6">
            <label>Format:</label>
            <input
              required
              className="w-100 "
              type="text"
              value={metadata?.format || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  format: e.target.value,
                })
              }
            />
          </div>

          {/* Codec */}
          <div className="mb-3 col-md-6">
            <label>Codec:</label>
            <input
              required
              className="w-100 "
              type="text"
              value={metadata?.codec || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  codec: e.target.value,
                })
              }
            />
          </div>

          {/* Ratio Aspect */}
          <div className="mb-3 col-md-6">
            <label>Ratio Aspect:</label>
            <input
              required
              className="w-100 "
              type="text"
              value={metadata?.ratioAspect || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  ratioAspect: e.target.value,
                })
              }
            />
          </div>

          {/* Image Rate */}
          <div className="mb-3 col-md-6">
            <label>Image Rate:</label>
            <input
              required
              className="w-100 "
              type="text"
              value={metadata?.imageRate || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  imageRate: e.target.value,
                })
              }
            />
          </div>

          {/* Bit Rate */}
          <div className="mb-3 col-md-6">
            <label>Bit Rate :</label>
            <input
              required
              className="w-100 "
              type="number"
              value={metadata?.bitRate || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  bitRate: parseInt(e.target.value) || null,
                })
              }
            />
          </div>

          {/* timecode */}
          <div className="mb-3 col-md-6">
            <label>Time code:</label>
            <input
              required
              className="w-100"
              type="text"
              step="1" // 1 jour
              value={(metadata?.timecode || []).join(", ") || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  timecode: e.target.value.split(", ").map((tc) => tc.trim()), // Séparez les timecodes et supprimez les espaces inutiles
                })
              }
            />
            <small>
              Entrez le timecode au format :{" "}
              <code>HH:MM:SS, HH:MM:SS, etc</code>
            </small>
          </div>

          {/* Require mat */}
          <div className="mb-3 col-md-6">
            <label>Matériaux requis: </label>
            <input
              required
              className="w-100"
              type="text"
              value={requireMatInput || ""}
              onChange={handleRequireMatChange}
              onBlur={handleRequireMatBlur}
            />
            <small>
              Entrez les matériaux requis au format :{" "}
              <code>Matériaux 1, Matérieux 2, etc</code>
            </small>
          </div>

          {/* language */}
          <div className="mb-3 col-md-6">
            <label>Language:</label>
            <input
              required
              className="w-100 "
              type="text"
              value={metadata?.language || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  language: e.target.value,
                })
              }
            />
          </div>

           {/* instrument */}
           <div className="mb-3 col-md-6">
            <label>Instrument:</label>
            <input
              required
              className="w-100 "
              type="text"
              value={metadata?.instrument || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  instrument: e.target.value,
                })
              }
            />
          </div>

           {/* thumbnail */}
           <div className="mb-3 col-md-6">
            <label>Thumbnail:</label>
            <input
              required
              className="w-100 "
              type="text"
              value={metadata?.thumbnail || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  thumbnail: e.target.value,
                })
              }
            />
          </div>

          {/* Description */}
          <div className="mb-3 col-md-6">
            <label>Description:</label>
            <textarea
              className="w-100 "
              value={metadata?.description || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  description: e.target.value,
                })
              }
            />
          </div>

          {/* duration */}
          <div className="mb-3 col-md-6">
            <label>Durée:</label>
            <input
              required
              className="w-100 "
              type="time"
              step="1" // Include seconds in the input
              value={metadata?.duration || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  duration: e.target.value,
                })
              }
            />
          </div>

          {/* Tag */}
          <div className="mb-3 col-md-6">
            <label>Tag:</label>
            <input
              required
              className="w-100 "
              type="text"
              step="1" // Include seconds in the input
              value={metadata?.tag || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  tag: e.target.value,
                })
              }
            />
            <small>
              Entrez les tags : <code>Tag 1, Tag 2, etc</code>
            </small>
          </div>

          {/* Place */}
          <div className="mb-3 col-md-6">
            <label>Lieu de tournage :</label>
            <input
              required
              className="w-100 "
              type="text"
              step="1" // Include seconds in the input
              value={metadata?.place || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  place: e.target.value,
                })
              }
            />
          </div>

          {/* theme */}
          <div className="mb-3 col-md-6">
            <label>Thèmes de la vidéo :</label>
            <input
              required
              className="w-100 "
              type="text"
              step="1" // Include seconds in the input
              value={metadata?.theme || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  theme: e.target.value,
                })
              }
            />
          </div>

          {/* Objectif */}
          <div className="mb-3 col-md-6">
            <label>Objectif de la vidéo :</label>
            <input
              required
              className="w-100 "
              type="text"
              step="1" // Include seconds in the input
              value={metadata?.objectif || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  objectif: e.target.value,
                })
              }
            />
          </div>

          {/* Level */}
          <div className="mb-3 col-md-6">
            <label>Level:</label>
            <input
              required
              className="w-100 "
              type="text"
              step="1" // Include seconds in the input
              value={metadata?.level || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  level: e.target.value,
                })
              }
            />
          </div>

            {/* staff */}
            <div className="mb-3 col-md-6">
            <label>Liste staff:</label>
            <input
              required
              className="w-100"
              type="text"
              value={(metadata?.staff || []).join(", ") || ""}
              onChange={(e) =>
                setMetadata({
                  ...metadata,
                  staff: e.target.value.split(", ").map((tc) => tc.trim()), // Séparez les timecodes et supprimez les espaces inutiles
                })
              }
            />
            <small>
              Entrez les noms au format :{" "}
              <code>nom1_prenom1, nom2_prenom2</code>
            </small>
          </div>


          <button className="btn btn-outline-secondary" type="submit">
            Enregistrer
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};
export default MetadataVersion;
