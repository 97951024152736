import React, {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import { DashboardAdminPropsInterface } from "../interface/DashboardAdminProps";
import useGetProjectByTeamId from "../hook/useGetProjectByTeamId";
import useGetIdProjectById from "../hook/useGetIdProjectById";
import Sidebar from "../components/Sidebar/index";
import CreateProject from "../components/Admin/Projet/CreateProjet";
import {UserContext} from "../context/UserContext";
export default function AdminDashboard({ team }: DashboardAdminPropsInterface) {
  const [user] = useContext(UserContext);
  const [selectedTeam, setSelectedTeam] = useState<number | null>(null);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const [isTeamSelected, setIsTeamSelected] = useState(false);
  const { allProject, isLoading } = useGetProjectByTeamId(selectedTeam);
  const { project: projectId } = useGetIdProjectById(
    selectedProject ? parseInt(selectedProject) : null
  );
  const navigate = useNavigate();

  const handleTeamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTeamId = Number(event.target.value);
    setSelectedTeam(selectedTeamId);
    setSelectedProject(null);
    setIsTeamSelected(true);
  };

  const handleProjectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProjectUrl = event.target.value;
    setSelectedProject(selectedProjectUrl.toString());
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedTeam !== null && projectId !== null) {
      const id = parseInt(selectedProject || "");
      const url = `/admin/dashboard/${selectedTeam}/projet/${id}`;
      navigate(url);
    } else {
      console.error("Nom de projet invalide");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="col-md-10 d-flex align-items-center justify-content-center">
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <div className="card p-4">
                  <h2 className="card-title mb-4 text-center">
                    Gerer un des projets
                  </h2>

                  <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <label htmlFor="selectTeam">Équipe :</label>
                      <select
                        id="selectTeam"
                        className="form-control"
                        value={selectedTeam || ""}
                        onChange={handleTeamChange}
                      >
                        <option value="">Sélectionnez une équipe</option>
                        {team &&
                          team.map((teamId) => (
                            <option key={teamId} value={teamId}>
                              Équipe {teamId}
                            </option>
                          ))}
                      </select>
                    </div>

                    {isTeamSelected && (
                      <>
                        {isLoading ? (
                          <div>Chargement...</div>
                        ) : (
                          <div className="form-group mb-3">
                            <label htmlFor="selectProject">Projet :</label>
                            <select
                              id="selectProject"
                              className="form-control"
                              value={selectedProject || ""}
                              onChange={handleProjectChange}
                            >
                              <option value="">Sélectionnez un projet</option>
                              {allProject?.map((project) => (
                                <option key={project.id} value={project.id}>
                                  {project.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </>
                    )}

                    <div className="mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary d-block mx-auto"
                        disabled={!selectedProject}
                      >
                        Gerer le projet
                      </button>
                    </div>
                  </form>
                </div>
                <h1 className="text-center mt-3"><strong>OU</strong></h1>
                <div className="card mt-3 p-4">
                  <CreateProject teamsData={user.teams} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
