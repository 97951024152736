import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/home";
import Contact from "./pages/contact";
import Dashboard from "./pages/Dashboard";
import StockageCommun from "./pages/stockageCommun";
import UploadVideo from "./pages/UploadVideo";
import Profile from "./pages/profile";
import AdminDashboard from "./pages/dashboardAdmin";
import ErrorPage from "./pages/error";
import { UserContext } from "./context/UserContext";
import React, { useContext, useEffect, useState } from "react";
import AdminRoute from "./security/AdminGuard/IsAdminRouteGuard";
import SpecificDashboard from "./pages/specificDashboardAdmin";
import MyProject from "./pages/myProject";
import AssetChoice from "./pages/AssetChoice";
import Assets from "./pages/Asset";
import Comments from "./pages/Comments";
import SuperAdminRoute from "./security/AdminGuard/IsSuperAdminRouteGuard";
import SuperAdminDashboard from "./pages/superAdminDashboard";
import Cookies from "js-cookie";
import Redacteur from "./pages/UploadRedacteur";

function App() {
  const [user] = useContext(UserContext);
  const [teamIds, setTeamIds] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const expiration = Cookies.get("exp");

  useEffect(() => {
    // Vérifier l'authentification et mettre à jour l'état `authenticated` une seule fois au montage du composant.
    const checkAuthentication = () => {
      if (token) {
        setAuthenticated(true);
        const teams = user.teams;
        const ids = teams.map((team) => team.id);
        setTeamIds(ids);
      }
    };

    const checkTokenExpiration = () => {
      if (token && expiration) {
        const currentTimestamp = Math.floor(Date.now() / 1000); // Timestamp actuel en secondes
        const exp = parseInt(expiration);

        if (currentTimestamp > exp) {
          // Le token a expiré
          Cookies.remove("token");
          Cookies.remove("exp");
          navigate("/");
        }
      }
    };

    checkAuthentication();
    checkTokenExpiration();
  }, [user]);

  return (
    <Routes>
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/" element={<Home />} />

      {/* Routes publiques */}
      <Route path="/contact" element={<Contact />} />
      <Route path="/my-project" element={<MyProject />} />
      <Route path="/profile" element={<Profile />} />

      {/* Vérifier si l'utilisateur est connecté avant de rendre les routes protégées */}
      {authenticated ? (
        <>
          {/* Routes protégées pour les utilisateurs connectés */}
          <Route
            path="/project/:projectId/assets/:assetId/dashboard"
            element={<Dashboard />}
          />

          <Route
            path="/project/:projectId/assets/:assetId/redacteur"
            element={<Redacteur />}
          />

          <Route
            path="/project/:projectId/assets/:assetId/add-video"
            element={<UploadVideo />}
          />
          <Route
            path="/project/:projectId/assets/:assetId/stockage"
            element={<StockageCommun />}
          />
          <Route path="/project/:projectId/assets" element={<AssetChoice />} />
          <Route
            path="/project/:projectId/assets/:assetId"
            element={<Assets />}
          />
          <Route
            path="/project/:projectId/assets/:assetId/comments"
            element={<Comments />}
          />
          <Route
            path="admin/dashboard"
            element={
              <AdminRoute isAdmin={user.roles.includes("ROLE_CHEF_PROJET")}>
                <AdminDashboard team={teamIds} />
              </AdminRoute>
            }
          />
          <Route
            path="admin/dashboard/:team/projet/:project"
            element={
              <AdminRoute isAdmin={user.roles.includes("ROLE_CHEF_PROJET")}>
                <SpecificDashboard />
              </AdminRoute>
            }
          />
          <Route
            path="super-admin"
            element={
              <SuperAdminRoute
                isSuperAdmin={user.roles.includes("ROLE_SUPER_ADMIN")}
              >
                <SuperAdminDashboard />
              </SuperAdminRoute>
            }
          />
          {/* Route pour la page 404 */}
          <Route path="*" element={<ErrorPage />} />
        </>
      ) : (
        // Rediriger l'utilisateur vers la page de connexion s'il n'est pas connecté
        <Route path="/" element={<Home />} />
      )}
    </Routes>
  );
}

export default App;
