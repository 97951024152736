import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import axios from "axios";
import Cookies from "js-cookie";
import Sidebar from "../components/Sidebar";
import { useParams } from "react-router-dom";
import AllComments from "../components/Comments";
import BackPage from "../components/Button/BackPage";
import { UserContext } from "../context/UserContext";

const Comments = () => {
  const { projectId } = useParams();
  const { assetId } = useParams();
  const [contentComment, setContentComment] = useState();
  const [comments, setComments] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const token = Cookies.get("token");
  const [user, setUser, getUserInfo] = useContext(UserContext);

  const fetchDataComments = async () => {
    const response = await axios.get(
      `http://dam.spiritofweb.fr:8741/api/project/${projectId}/assets/${assetId}/comments`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Inclut le JWT dans l'en-tête Authorization
        },
      }
    );
    setComments(response.data);
    setIsLoading(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      axios.defaults.headers.post["Content-Type"] = "application/json";
      const response = await axios.post(
        `http://dam.spiritofweb.fr:8741/api/project/${projectId}/assets/${assetId}/comments`,
        JSON.stringify({ content: contentComment }),
        {
          headers: {
            Authorization: `Bearer ${token}`, // Inclut le JWT dans l'en-tête Authorization
          },
        }
      );
      fetchDataComments();
    } catch (error) {
      console.log("Erreur :", error);
    }
  };

  useEffect(() => {
    fetchDataComments();
    // Mettre en place le polling toutes les 5 secondes (par exemple)
    const pollingInterval = setInterval(fetchDataComments, 1000);

    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(pollingInterval);
  }, []);

  return isLoading ? (
    <React.Fragment>
      <p>En cours de chargement...</p>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="container-fluid">
        <div className="col-12">
          <div className="row">
            <Sidebar></Sidebar>
            <div className="col-8">
              <BackPage inBtn="&larr; Retournez aux info de l'asset" />

              <h1 className="text-center">Ma team : {comments.teamName}</h1>

              <AllComments
                comments={comments}
                setComments={setComments}
                contentComment={contentComment}
                setContentComment={setContentComment}
                user={user}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Comments;
