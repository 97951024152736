import axios from 'axios';
import Cookies from "js-cookie";

const useDeleteUserFromTeamById = () => {
    const token = Cookies.get('token');

    const deleteUserFromTeam = async (teamId: number, userId: number) => {
        const header = {
            Authorization: `Bearer ${token}`
        }
        try {
            if (teamId && userId) {
                const response = await axios.delete(`http://dam.spiritofweb.fr:8741/api/team/${teamId}/user/${userId}`, {headers: header});
            }
        } catch (error) {
            console.error(error);
        }
    };

    return deleteUserFromTeam;
};

export default useDeleteUserFromTeamById;
