import React from "react";
import {AdminRouteProps} from "../../interface/AdminRouteProps";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

interface SuperAdminRouteProps {
    isSuperAdmin: boolean;
    children: React.ReactNode;
}

export default function SuperAdminRoute({ isSuperAdmin, children }: SuperAdminRouteProps) {
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserRole = async () => {
            if (!isSuperAdmin) {
                navigate('/error');
            }
        };

        checkUserRole();
    }, [isSuperAdmin, navigate]);

    if (isSuperAdmin) {
        return children;
    } else {
        return null;
    }
}