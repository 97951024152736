import React from "react";
import Sidebar from "./Sidebar";
import avatar from '../public/avatar.png';
import StockageCommun from "../pages/stockageCommun";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

export default function Welcome() {
    const navigate = useNavigate();
    const handleLogout = () => {
        Cookies.remove('token');
        const url = "/";
        navigate(url);
        // Autres actions de déconnexion ou redirection ici
    };
    return(
        <div className="col-2">
          <div className="dropdown">
              <button className="btn border-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={avatar}></img>
              </button>
              <ul className="dropdown-menu">
                <li><button className="dropdown-item" type="button">Mon profil</button></li>
                <li><button className="dropdown-item" type="button" onClick={handleLogout}>Se déconnecter</button></li>
              </ul>
              <span className="ms-3">Bienvenue, </span>
          </div>
        </div>
    )
}