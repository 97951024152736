import {ProfilProps} from "../../../interface/User";
import usePatchUser from "../../../hook/usePatchUser";
import React, {useState} from "react";
import {UserData} from "../../../interface/UserDataInterface";
import bcrypt from "bcryptjs-react";
export default function UpdatePassword({userId}: ProfilProps) {
    const patchUser = usePatchUser();
    const [password, setUpdatePassword] = useState("");
    const [updateSuccess, setUpdateSuccess] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (userId) {
            const dataToUpdate: UserData = {};
            if (password !== "") {
                const saltRounds = 10;
                dataToUpdate.password = await bcrypt.hash(password, saltRounds);
            }
            await patchUser(userId, dataToUpdate);
            setUpdateSuccess(true);
        }
    };

    return (
        <div className="container mt-4">
            <h3>Update password</h3>
            <div className="row">
                <div className="col-12">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Mot de passe:</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                value={password}
                                onChange={(event) => setUpdatePassword(event.target.value)}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">Modifier le mot de passe</button>
                    </form>
                    {updateSuccess && (
                        <div className="alert alert-success mt-4">
                            <p>Le mot de passe a bien été modifié.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}