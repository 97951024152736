import React from "react";
import FormCreateTeam from "./FormCreateTeam";
import DeleteTeam from "./DeleteTeam";

export default function TeamSuperAdmin() {

    return(
        <>
            <FormCreateTeam/>
            <DeleteTeam/>
        </>
    );
}