import React from 'react'

export default function Login(props) {
return (

<form className='w-50 mx-auto d-flex flex-column' onSubmit={props.onSubmit}>
    <div className="form mb-3">
        <label htmlFor="floatingInput" className='text-light'>Email</label>
        <input type="email" className="form-control mt-2 rounded-4" id="floatingInput" placeholder="name@example.com"  value={props.email}
            onChange={(event) => {
              props.setEmail(event.target.value);
            }} />
    </div>
    <div className="form">
        <label htmlFor="floatingPassword" className='text-light'>Mot de passe</label>
        <input type="password" className="form-control mt-2 rounded-4" id="floatingPassword" placeholder="Mot de passe"  value={props.password}
            onChange={(event) => {
              props.setPassword(event.target.value);
            }} />
    </div>
    {props.error && <p className="text-white mt-3 p-1 bg-danger border border-danger rounded">{props.error}</p>}
    <button type="submit" className="btn btn-warning text-light w-25 mx-auto mt-5">Se connecter</button>

</form>

)
}