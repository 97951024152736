import { useEffect, useState } from 'react';
import axios from 'axios';
import {Project} from "../interface/ProjectIdinterface";
import Cookies from "js-cookie";
import {Icon} from "../interface/IconInterface";

const useGetAllIcon = () => {
    const [icons, setIcons] = useState<Icon[] | null>(null);

    const token = Cookies.get('token');

    useEffect(() => {
        const fetchIcon = async () => {
            const header = {
                "Accept": "application/json",
                Authorization: `Bearer ${token}`,
            }
            try {

                const response = await axios.get('http://dam.spiritofweb.fr:8741/api/icons', {headers: header})
                setIcons(response.data);

            } catch (error) {
                console.error(error);
            }
        };

        fetchIcon();
    }, []);

    return {
        icons,
    };
};

export default useGetAllIcon;